import React from 'react';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Navbar from 'containers/SaasModern/Navbar';
const Header = () => {
  return (
    <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
      <DrawerProvider>
        <Navbar />
      </DrawerProvider>
    </Sticky>
  );
};

export default Header;
