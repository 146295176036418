import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';

import Box from 'common/components/Box';
import HamburgMenu from 'common/components/HamburgMenu';
import Container from 'common/components/UI/Container';
import { DrawerContext } from 'common/contexts/DrawerContext';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';

import { StaticImage } from 'gatsby-plugin-image';
const Navbar = ({ navbarStyle, row, menuWrapper }) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MENU_ITEMS {
          label
          path
          offset
        }
      }
    }
  `);

  const { state, dispatch } = useContext(DrawerContext);

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className='saas_navbar'>
      <Container>
        <Box {...row}>
          <div>
            <a href='/'>
              <StaticImage
                src='../../../common/assets/image/saasModern/pitto.png'
                style={{
                  width: '100px',
                  height: '30px',
                }}
                alt='logo'
                quality='60'
                placeholder='blurred'
              />
            </a>
          </div>
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className='main_menu'
              menuItems={Data.saasModernJson.MENU_ITEMS}
              offset={-70}
            />
            <Drawer
              width='420px'
              placement='right'
              drawerHandler={<HamburgMenu barColor='#fff' />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className='mobile_menu'
                menuItems={Data.saasModernJson.MENU_ITEMS}
                drawerClose={true}
                offset={-100}
              />
              <div class='btn_qForm_wrapper'>
                <a
                  href='https://tayori.com/form/09fcd93a0ceee3f0c98861cc234affbc09d6306e/'
                  class='btn_qForm bgskew'
                >
                  <span>無料体験お申し込み</span>
                </a>
              </div>
            </Drawer>
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '50px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
