const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  headingColor: "#302b4e",
  textColor: "#43414e", // 3
  labelColor: "#767676", // 4
  inactiveField: "#f2f2f2", // 5
  inactiveButton: "#b7dbdd", // 6
  inactiveIcon: "#EBEBEB", // 7
  primary: "#00a29a", // 8
  primaryHover: "#00a29a", // 9
  secondary: "#ffdead", // 10
  secondaryHover: "#ffdead", // 11
  yellow: "#fdb32a", // 12
  yellowHover: "#F29E02", // 13
  borderColor: "#1b1e25", //14
  primaryBoxShadow: "0px 8px 20px -6px rgba(42, 162, 117, 0.57)",
  secondaryBoxShadow: "0px 8px 20px -6px rgba(237, 205, 55, 0.5)",
};

export default colors;
